<template>
  <main id="thank-smartmeter" class="next-container">
    <section-base>
      <div class="heading-col top-thank-sm">
        <h1 class="color-primary-100">
          Vielen Dank für Ihre SmartMeter-Bestellung
        </h1>

        <p class="text-subtitle color-accent-300 text-breaks">
          Sie erhalten in Kürze per E-Mail weitere Informationen
          <br />
          von uns zu den nächsten Schritten.
        </p>
      </div>

      <button-regular class="button-accent" @click="openEprimo">
        eprimo.de besuchen
      </button-regular>
    </section-base>

    <section-base>
      <accordion-faq item="strom">
        <template #title>
          <h1 class="color-primary-100 heading-col">
            Stromzähler - FAQs
          </h1>
        </template>
      </accordion-faq>
    </section-base>
  </main>
</template>

<script>
import SectionBase from '@/components/next/section-base.vue';
import AccordionFaq from '@/components/next/accordion/accordion-faq.vue';
import ButtonRegular from '@/components/next/button-regular.vue';

export default {
  components: { AccordionFaq, ButtonRegular, SectionBase },

  methods: {
    openEprimo() {
      window.open('https://eprimo.de');
    }
  }
};
</script>
